import React, { useCallback } from "react";
import styles from "./Button.module.scss";
import { useCobrandingSettings } from "../lib/theme";

export interface ButtonProps {
	text: string | React.ReactNode;
	onClick: () => void;
	tabIndex?: number;
	image?: string;
	style?: ButtonStyle;
	disabled?: boolean;
}

export enum ButtonStyle {
	Filled,
	Light,
	Alternate,
	Gradient,
	Gray,
	NoBackground,
	NoBackGroundMobile
}

export const Button = (props: ButtonProps): JSX.Element => {
	const {
		text,
		onClick,
		tabIndex = 0,
		image,
		style = ButtonStyle.Filled,
		disabled = false
	} = props;

	const { enableCobranding, brandColor } = useCobrandingSettings();
	const cssProperties = getCSSPropertiesForStyle(style, enableCobranding, brandColor);

	const onClickEnriched = useCallback(() => {
		if (disabled) {
			return;
		}

		onClick();
	}, [disabled, onClick]);

	const classNames = [
		styles.button,
		getClassNameForStyle(style)
	];

	if (disabled) {
		classNames.push(styles.disabled);
	}

	return (
		<div tabIndex={disabled ? undefined : tabIndex} className={classNames.join(" ")} style={cssProperties} onClick={onClickEnriched}>
			<div>{text}</div>
			{image && <img src={image} />}
		</div>
	);
};

const getClassNameForStyle = (style: ButtonStyle): string => {
	switch (style) {
	case ButtonStyle.Light:
		return styles.light;
	case ButtonStyle.Alternate:
		return styles.alternate;
	case ButtonStyle.Gradient:
		return styles.gradient;
	case ButtonStyle.Gray:
		return styles.gray;
	case ButtonStyle.NoBackground:
		return styles.noBackground;
	case ButtonStyle.NoBackGroundMobile:
		return styles.noBackgroundMobile;
	default:
		return styles.filled;
	}
};

const getCSSPropertiesForStyle = (style: ButtonStyle, enableCobranding: boolean, brandColor: string): React.CSSProperties | undefined => {
	switch (style) {
	case ButtonStyle.Filled:
		return {
			backgroundColor: enableCobranding ? brandColor : "#5118EE"
		};
	default:
		return undefined;
	}
};
