import React from "react";
import { Container } from "src/lib/components/Container";
import { Limit } from "src/lib/components/Limit";
import { TitleBar } from "src/lib/components/TitleBar";
import { useMerchants } from "../api/useMerchants";
import { ContainerListItem } from "src/lib/components/ContainerListItem";
import { Center, ContainerSegment, ErrorDisplay, InfiniteScroller, Loader, Stack } from "shared/components";
import { useTitle } from "src/lib/useTitle";

export const PageMerchants = (): JSX.Element => {
	useTitle("Merchants");

	const merchants = useMerchants();
	const rows = !merchants.data ? [] : merchants.data.pages.flatMap(x => x.results);

	return (
		<Limit>
			<TitleBar title="Merchants" />

			<Stack gap={32}>
				{
					merchants.error &&
					<Container>
						<ContainerSegment>
							<ErrorDisplay problemResponse={merchants.error} />
						</ContainerSegment>
					</Container>
				}

				{
					merchants.isLoading &&
					<Center><Loader /></Center>
				}

				{
					merchants.data &&
					<Container>
						{
							rows.map(x => (
								<ContainerListItem
									key={x.merchantId}
									title={x.name}
									subtitle={x.merchantId}
									link={`/merchants/${x.merchantId}`} />
							))
						}
					</Container>
				}

				<InfiniteScroller forQuery={merchants} />
			</Stack>
		</Limit>
	);
};
