import { MutationFunction, useMutation } from "react-query";
import { useUserAuthState } from "../UserAuthState";

export interface GetADTokensRequestParameters {
	tenant: string;
	clientId: string;
	code: string;
	redirectUri: string;
	grantType: string;
	codeVerifier: string;
}

export interface GetADTokensResponseModel {
	access_token: string;
	id_token: string;
}

interface GetADTokenError {
	error_description: string;
}

export const useGetADTokens = () => {
	const { dispatchLogin } = useUserAuthState();

	const mutFunc: MutationFunction<GetADTokensResponseModel, GetADTokensRequestParameters> = async requestParameters => {
		const response = await fetch(`https://login.microsoftonline.com/${requestParameters.tenant}/oauth2/v2.0/token`, {
			method: "POST",
			headers: {
				"Content-Type": "application/x-www-form-urlencoded"
			},
			body: new URLSearchParams({
				client_id: requestParameters.clientId,
				code: requestParameters.code,
				redirect_uri: requestParameters.redirectUri,
				grant_type: requestParameters.grantType,
				code_verifier: requestParameters.codeVerifier
			})
		});

		if (response.status !== 200) {
			const error: GetADTokenError = await response.json();
			throw new Error(error.error_description);
		}

		return await response.json();
	};

	return useMutation<GetADTokensResponseModel, Error, GetADTokensRequestParameters>(mutFunc, {
		onSuccess: data => dispatchLogin(data.access_token, data.id_token)
	});
};
