import React from "react";
import styles from "./StyledTable.module.scss";

export interface StyledTableProps {
	children: React.ReactNode;
}

export const StyledTable = ({ children }: StyledTableProps): JSX.Element => {
	return (
		<div className={styles.styledTable}>
			<table>
				{children}
			</table>
		</div>
	);
};
