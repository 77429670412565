import React, { useEffect } from "react";
import { useEffectOnFirstMount, useFragmentParams } from "shared/hooks";
import { useGetADTokens } from "../api/useGetADTokens";
import { ADMIN_BASE_URL, AD_ADMIN_APP_CLIENT_ID, ARM_TENANT_ID } from "src/lib/config";
import { StorageService } from "shared/lib/storage";
import { useUserAuthState } from "../UserAuthState";
import { useNavigate } from "react-router-dom";
import { Center, ErrorDisplay, Loader, LoaderSize } from "shared/components";
import { useTitle } from "src/lib/useTitle";

export const PageLoginCallback = (): JSX.Element => {
	useTitle("Logging in...");

	const fragmentParams = useFragmentParams();
	const getADTokens = useGetADTokens();
	const navigate = useNavigate();
	const { state } = useUserAuthState();

	const code = fragmentParams.get("code");
	const stateToken = fragmentParams.get("state");
	const errorDescription = fragmentParams.get("error_description");

	useEffectOnFirstMount(() => {
		const codeVerifier = StorageService.getTemporary<string>("AdminCodeVerifier");
		const storedStateToken = StorageService.getTemporary<string>("AdminStateToken");
		if (!code || !AD_ADMIN_APP_CLIENT_ID || !codeVerifier || errorDescription || stateToken != storedStateToken) {
			// TODO: Handle this better.
			return;
		}

		StorageService.removeTemporary("AdminCodeVerifier");
		StorageService.removeTemporary("AdminStateToken");

		getADTokens.mutate({
			tenant: ARM_TENANT_ID,
			clientId: AD_ADMIN_APP_CLIENT_ID,
			code: code,
			redirectUri: `${ADMIN_BASE_URL}/login/callback`,
			grantType: "authorization_code",
			codeVerifier: codeVerifier
		});
	});

	useEffect(() => {
		if (!state.accessToken || !state.idToken) {
			return;
		}

		let postLoginDestination = StorageService.getTemporary<string>("PostLoginDestination");
		StorageService.removeTemporary("PostLoginDestination");

		if (!postLoginDestination) {
			postLoginDestination = "/dashboard";
		}

		navigate(postLoginDestination, { replace: true });
	}, [state.accessToken, state.idToken]);

	if (errorDescription) {
		return (
			<Center>
				<ErrorDisplay message={errorDescription} />
			</Center>
		);
	}

	if (getADTokens.error) {
		return (
			<Center>
				<ErrorDisplay message={getADTokens.error.message} />
			</Center>
		);
	}

	return (
		<Center>
			<Loader size={LoaderSize.Large} />
		</Center>
	);
};
