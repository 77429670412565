import React, { useMemo } from "react";

export interface MoneyDisplayProps {
	amount: number;
	allowDecimalTruncation?: boolean;
	forceLeadingSymbol?: boolean;
	className?: string;
}

export const MoneyDisplay = (props: MoneyDisplayProps): JSX.Element => {
	const {
		amount,
		allowDecimalTruncation = false,
		forceLeadingSymbol = false,
		className
	} = props;

	const amountDisplay = useMemo(() => {
		const formatted = amount.toLocaleString(undefined, {
			style: "currency",
			signDisplay: forceLeadingSymbol ? "always" : undefined,
			currency: "USD", // TODO: i18n concern.
			currencyDisplay: "narrowSymbol",
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		});

		if (allowDecimalTruncation && formatted.indexOf(".00") === formatted.length - 3) {
			return formatted.substring(0, formatted.length - 3);
		}

		return formatted;
	}, [amount, allowDecimalTruncation, forceLeadingSymbol]);

	return (
		<span className={className}>{amountDisplay}</span>
	);
};
