import React, { useState } from "react";
import styles from "./DateRangeSelector.module.scss";
import rightArrow from "src/assets/right-arrow.svg";
import dayjs from "dayjs";
import { AdminModal } from "./AdminModal";

export interface DateRangeSelectorProps {
	from: Date;
	to: Date;
	onChangeFrom: (from: Date) => void;
	onChangeTo: (to: Date) => void;
}

export const DateRangeSelector = ({ from, to, onChangeFrom, onChangeTo }: DateRangeSelectorProps): JSX.Element => {
	const [fromModalVisible, setFromModalVisible] = useState(false);
	const [toModalVisible, setToModalVisible] = useState(false);

	return (
		<>
			<div className={styles.dateRangeSelector}>
				<div onClick={() => setFromModalVisible(true)}>{dayjs(from).format("DD MMM YYYY")}</div>
				<img className={styles.arrow} src={rightArrow} />
				<div onClick={() => setToModalVisible(true)}>{dayjs(to).format("DD MMM YYYY")}</div>
			</div>

			<ModalDatePicker value={from} onChange={onChangeFrom} onCancel={() => setFromModalVisible(false)} visible={fromModalVisible} />
			<ModalDatePicker value={to} onChange={onChangeTo} onCancel={() => setToModalVisible(false)} visible={toModalVisible} />
		</>
	);
};

interface ModalDatePickerProps {
	visible: boolean;
	value: Date;
	onChange: (value: Date) => void;
	onCancel: () => void;
}

const ModalDatePicker = ({ visible, value, onChange, onCancel }: ModalDatePickerProps): JSX.Element => {
	const onChangeEnriched = (value: Date) => {
		onChange(value);
		onCancel();
	};

	return (
		<AdminModal visible={visible} onCancel={onCancel} title="Select Date">
			<input
				className={styles.dateInput}
				type="date"
				value={dayjs(value).format("YYYY-MM-DD")}
				onChange={e => onChangeEnriched(dayjs(e.target.value, "YYYY-MM-DD").toDate())} />
		</AdminModal>
	);
};
