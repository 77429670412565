import React from "react";

export interface StackProps {
	children: React.ReactNode;
	gap?: number;
}

export const Stack = ({ children, gap = 24 }: StackProps): JSX.Element => {
	return (
		<div>
			{
				React.Children.map(children, (x, i) => i == React.Children.count(children) - 1
					? <div>{x}</div>
					: <div style={{ marginBottom: `${gap}px` }}>{x}</div>)
			}
		</div>
	);
};
