import React from "react";
import { UseMutationResult, UseQueryResult } from "react-query";
import { ProblemResponse } from "../lib/http";
import { Center } from "./Center";
import { ErrorDisplay } from "./ErrorDisplay";
import { Loader } from "./Loader";

export interface SmartBoxProps<T, U> {
	readonly switchOn: UseQueryResult<T, ProblemResponse> | UseMutationResult<T, ProblemResponse, U>;
	readonly loading?: () => JSX.Element;
	readonly success?: (data: T) => JSX.Element;
	readonly error?: (error: ProblemResponse) => JSX.Element;
}

export const SmartBox = <T, U>(props: SmartBoxProps<T, U>): JSX.Element => {
	const {
		switchOn,
		loading,
		success,
		error
	} = props;

	if (switchOn.isIdle) {
		return <></>;
	}

	if (switchOn.isLoading) {
		return loading
			? loading()
			: <Center><Loader /></Center>;
	}

	if (success && switchOn.isSuccess) {
		return success(switchOn.data);
	}

	if (switchOn.isError) {
		return error
			? error(switchOn.error)
			: <ErrorDisplay problemResponse={switchOn.error}/>;
	}

	return <></>;
};

