import { QueryFunction, QueryKey, useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { ProblemResponse } from "./ProblemResponse";
import { Response } from "./Response";

export const useHTTPQuery = <T>(
	queryKey: QueryKey,
	queryFunc: QueryFunction<Response<T>>,
	options: Omit<UseQueryOptions<T, ProblemResponse>, "queryKey" | "queryFn"> = {}
): UseQueryResult<T, ProblemResponse> => {
	return useQuery(queryKey, async ctx => {
		const result = await Promise.resolve(queryFunc(ctx));
		return result.body;
	}, options);
};

export default useHTTPQuery;
