import { Limit } from "src/lib/components/Limit";
import React from "react";
import { useParams } from "react-router-dom";
import { useCustomerDetails } from "../api/useCustomerDetails";
import { Center, Loader, ErrorDisplay, Stack } from "shared/components";
import { TitleBar } from "src/lib/components/TitleBar";
import { ContainerListItem } from "src/lib/components/ContainerListItem";
import { Container } from "src/lib/components/Container";
import { ContainerSegment } from "src/lib/components/ContainerSegment";
import { DetailsContainer } from "src/lib/components/DetailsContainer";
import { SubHeader } from "src/lib/components/SubHeader";
import { BoxContainer } from "src/lib/components/BoxContainer";
import { Box } from "src/lib/components/Box";
import { AiOutlineShareAlt } from "react-icons/ai";
import { PiMouseDuotone } from "react-icons/pi";
import { MdRedeem } from "react-icons/md";


export const PageCustomerDetails = (): JSX.Element => {
	const { customerId = "", merchantId = "" } = useParams();
	const customer = useCustomerDetails(customerId, merchantId);

	if (customer.isLoading || !customer.data) {
		return (
			<Limit>
				<Center><Loader /></Center>
			</Limit>
		);
	}

	if (customer.error) {
		return (
			<Limit>
				<Container>
					<ContainerSegment>
						<ErrorDisplay problemResponse={customer.error} />
					</ContainerSegment>
				</Container>
			</Limit>
		);
	}

	const {
		firstName,
		lastName,
		handle,
		email,
		phoneNumber,
		totalShares,
		totalClicks,
		totalRedemptions

	} = customer.data;

	const fullName = firstName + " " + lastName;

	return (
		<>
			<Limit>
				<DetailsContainer>
					<TitleBar title={fullName} />
					<SubHeader subHeader="Customer Details" />
					<Stack gap={32}>
						<Container>
							<ContainerListItem title="Name">
								{firstName} {lastName}
							</ContainerListItem>

							<ContainerListItem title="Handle">
								{!handle && "N/A"} {handle}
							</ContainerListItem>

							<ContainerListItem title="Email">
								{!email && "N/A"} {email}
							</ContainerListItem>

							<ContainerListItem title="Phone Number">
								{!phoneNumber && "N/A"}{phoneNumber}
							</ContainerListItem>

						</Container>
					</Stack>
					<BoxContainer>
						<Box title="Shares"><AiOutlineShareAlt />{totalShares}</Box>
						<Box title="Clicks"><PiMouseDuotone />{totalClicks}</Box>
						<Box title="Redemptions"><MdRedeem />{totalRedemptions}</Box>
					</BoxContainer>

				</DetailsContainer>
			</Limit>
		</>
	);
};