import { MutationFunction, useQueryClient } from "react-query";
import { Response, useHTTP, useHTTPMutation } from "shared/lib/http";
import { useUserAuthState } from "src/features/auth/UserAuthState";
import { API_BASE_URL } from "src/lib/config";

export interface CommunicationPreferencesOptions {
	merchantId: string;
	requestModel: SetCommunicationPreferencesRequestModel;
}

export interface SetCommunicationPreferencesRequestModel {
	readonly allowCustomerCommunications: boolean;
}

export const useSetMerchantCommuncation = () => {
	const queryClient = useQueryClient();
	const { state } = useUserAuthState();
	const http = useHTTP(state.accessToken);

	const mutFunc: MutationFunction<Response<undefined>, CommunicationPreferencesOptions> = (options: CommunicationPreferencesOptions) => {
		return http<undefined, SetCommunicationPreferencesRequestModel>({
			method: "POST",
			url: `${API_BASE_URL}/admin/merchant/${options.merchantId}/communication-preferences`,
			body: options.requestModel
		});
	};

	return useHTTPMutation(mutFunc, {
		onSuccess: (_, options) => {
			queryClient.invalidateQueries(["merchant", options.merchantId]);
		}
	});
};
