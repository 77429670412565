import React from "react";
import styles from "./Select.module.scss";

export interface SelectProps<T> {
	value: T;
	onChange: (value: T) => void;
	options: SelectOption<T>[];
}

export interface SelectOption<T> {
	text: string;
	value: T;
}

export const Select = <T,>({ value, onChange, options }: SelectProps<T>): JSX.Element => {
	const selectedIndex = options.findIndex(x => x.value === value);

	return (
		<select className={styles.select} value={selectedIndex} onChange={e => onChange(options[e.target.selectedIndex].value)}>
			{
				options.map((x, i) => (
					<option key={i} value={i}>{x.text}</option>
				))
			}
		</select>
	);
};
