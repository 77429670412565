import React from "react";
import styles from "./EducationTile.module.scss";
import { useCobrandingSettings } from "../lib/theme";

export interface EducationTileProps {
	icon?: string;
	character?: string;
	style?: EducationTileStyle;
	title: React.ReactNode;
	description: React.ReactNode;
}

export enum EducationTileStyle {
	Standard,
	Alternate,
	Filled
}

export const EducationTile = (props: EducationTileProps): JSX.Element => {
	const {
		icon,
		character,
		style = EducationTileStyle.Standard,
		title,
		description
	} = props;

	const {
		enableCobranding,
		brandColor
	} = useCobrandingSettings();

	const characterStyle: React.CSSProperties = {
		color: enableCobranding ? brandColor : "#5118EE",
		borderColor: enableCobranding ? brandColor : "#5118EE"
	};

	const iconStyle: React.CSSProperties = style === EducationTileStyle.Standard
		? { borderColor: enableCobranding ? brandColor : "#5118EE" }
		: { };

	const classNames = [styles.educationTile];
	classNames.push(getClassNameForStyle(style));

	return (
		<div className={classNames.join(" ")}>
			{
				icon &&
				<div className={styles.icon} style={iconStyle}>
					<img src={icon} />
				</div>
			}

			{
				character &&
				<div className={styles.character} style={characterStyle}>
					<div>{character}</div>
				</div>
			}

			<div className={styles.text}>
				<div>{title}</div>
				<div>{description}</div>
			</div>
		</div>
	);
};

const getClassNameForStyle = (style: EducationTileStyle): string => {
	switch (style) {
	case EducationTileStyle.Alternate:
		return styles.alternate;
	case EducationTileStyle.Filled:
		return styles.filled;
	default:
		return styles.standard;
	}
};
