import { MutationFunction, useQueryClient } from "react-query";
import { Response, useHTTP, useHTTPMutation } from "shared/lib/http";
import { useUserAuthState } from "src/features/auth/UserAuthState";
import { API_BASE_URL } from "src/lib/config";

export interface MergeCustomersRequestModel {
	readonly emailAddress: string;
	readonly amount: number;
}

export const useManualDisbursement = () => {
	const { state } = useUserAuthState();
	const http = useHTTP(state.accessToken);
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response<undefined>, MergeCustomersRequestModel> = requestModel => {
		return http<undefined, MergeCustomersRequestModel>({
			method: "POST",
			url: `${API_BASE_URL}/admin/customer/manual-disbursement`,
			body: requestModel
		});
	};

	return useHTTPMutation(mutFunc, {
		onSuccess: (_, { emailAddress }) => {
			queryClient.invalidateQueries(["customerTransactions", emailAddress]);
		}
	});
};
