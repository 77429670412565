import React from "react";
import styles from "./Center.module.scss";

export interface CenterProps {
	children: React.ReactNode;
}

export const Center = ({ children }: CenterProps): JSX.Element => {
	return (
		<div className={styles.center}>
			{children}
		</div>
	);
};
