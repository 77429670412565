import React, { createContext, useContext } from "react";

export interface CobrandingSettings {
	enableCobranding: boolean;
	brandColor: string;
	secondaryColor: string;
	logoUrl: string | null;
}

const getDefaultCobrandingSettings = () => ({
	enableCobranding: false,
	brandColor: "#FFFFFF",
	secondaryColor: "#333333",
	logoUrl: null
});

const ctx = createContext<CobrandingSettings>(getDefaultCobrandingSettings());

export const useCobrandingSettings = () => useContext(ctx);

export interface CobrandingSettingsProviderProps {
	cobrandingSettings: CobrandingSettings;
	children: React.ReactNode;
}

export const CobrandingSettingsProvider = ({ cobrandingSettings, children }: CobrandingSettingsProviderProps) => {
	return (
		<ctx.Provider value={cobrandingSettings}>
			{children}
		</ctx.Provider>
	);
};
