import React, { Children } from "react";
import styles from "./ContainerControls.module.scss";

export interface ContainerControls {
	children: React.ReactNode;
}

export const ContainerControls = ({ children }: ContainerControls): JSX.Element => {
	const childrenArr = Children.toArray(children);

	return (
		<div className={styles.containerControls}>
			{
				Children.map(childrenArr, x => (
					<div className={styles.control}>
						{x}
					</div>
				))
			}
		</div>
	);
};
