import { PaginatedResponseModel } from "./PaginatedResponseModel";
import { QueryFunction, QueryFunctionContext, QueryKey, useInfiniteQuery, UseInfiniteQueryResult } from "react-query";
import { Response } from "./Response";
import { ProblemResponse } from "./ProblemResponse";

export const useHTTPQueryWithCursor = <T, U extends PaginatedResponseModel<T>>(
	queryKey: QueryKey,
	queryFunc: QueryFunction<Response<PaginatedResponseModel<T>>>
): UseInfiniteQueryResult<U, ProblemResponse> => {
	return useInfiniteQuery(queryKey, async ctx => {
		const result = await Promise.resolve(queryFunc(ctx));
		return result.body;
	}, {
		getNextPageParam: lastPage => lastPage.nextCursor
	});
};

export type InfiniteQueryPageContext = QueryFunctionContext<QueryKey, number>;
