import React, { createContext, useContext } from "react";

export interface SharedConfig {
	apiBaseUrl: string;
}

const ctx = createContext<SharedConfig>(null as unknown as SharedConfig);

export const useSharedConfig = () => useContext(ctx);

export interface SharedConfigProviderProps {
	sharedConfig: SharedConfig;
	children: React.ReactNode;
}

export const SharedConfigProvider = ({ sharedConfig, children }: SharedConfigProviderProps) => {
	return (
		<ctx.Provider value={sharedConfig}>
			{children}
		</ctx.Provider>
	);
};
