import React, { useEffect } from "react";
import { InfiniteScroller, Stack } from "shared/components";
import { useMerchantList } from "src/features/common/api/useMerchantList";
import { Limit } from "src/lib/components/Limit";
import { MediaList } from "src/lib/components/MediaList";
import { Select } from "src/lib/components/Select";
import { TitleBar } from "src/lib/components/TitleBar";
import { useUgcSubmissions } from "../api/useUgcSubmissions";
import { ContainerListItem } from "src/lib/components/ContainerListItem";
import dayjs from "dayjs";
import { Container } from "src/lib/components/Container";
import { ContainerSegment } from "src/lib/components/ContainerSegment";

export const PageUgc = (): JSX.Element => {

	const merchantList = useMerchantList();
	const [merchantId, setMerchantId] = React.useState<string | null>(null);

	if (!merchantList.data)
		return <></>;


	return (
		<Limit>
			<TitleBar title="UGC" />
			<Select
				onChange={setMerchantId}
				value={merchantId}
				options={[
					{ text: "All merchants", value: null },
					...merchantList.data.map(x => ({
						text: x.name,
						value: x.id
					}))
				]} />
			<UgcSubmissionList merchantId={merchantId} />

		</Limit>
	);
};

const UgcSubmissionList = (props: { merchantId: string | null }) => {
	const merchantId = props.merchantId;
	const ugcSubmissions = useUgcSubmissions(merchantId);
	const rows = !ugcSubmissions.data ? [] : ugcSubmissions.data.pages.flatMap(x => x.results);

	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD"
	});

	useEffect(() => {
		ugcSubmissions.refetch();
	}, [merchantId]);

	if (rows.length === 0)
		return (
			<Container>
				<ContainerSegment>
					No UGC submissions found
				</ContainerSegment>
			</Container>
		);

	return (
		<Stack gap={32}>
			<Container>
				{rows.map((x, index) =>
					<>
						<ContainerListItem
							key={index}
							title={`${x.customerName} - ${dayjs(x.createdAt).format("DD MMM YYYY")} - ${x.merchantName}`}
							subtitle={`${x.status}${x.rewardValue ? ` - ${formatter.format(x.rewardValue)}` : ""}`}
						>
						</ContainerListItem>
						<ContainerSegment>
							{x.medias && x.medias.length > 0 && (
								<MediaList
									medias={x.medias}
								/>
							)}
						</ContainerSegment>
					</>
				)}
			</Container>

			<InfiniteScroller forQuery={ugcSubmissions} />
		</Stack>
	);
};
