import { useHTTP, useHTTPQuery } from "shared/lib/http";
import { useUserAuthState } from "src/features/auth/UserAuthState";
import { API_BASE_URL } from "src/lib/config";
import { GetCustomerResponseModel } from "./models/GetCustomerResponseModel";

export const useCustomerDetails = (customerId: string, merchantId: string) => {
	const {state} = useUserAuthState();
	const http = useHTTP(state.accessToken);

	return useHTTPQuery(["customer", merchantId, customerId], () => {
		return http<GetCustomerResponseModel>({
			method: "GET",
			url: `${API_BASE_URL}/admin/merchant/${merchantId}/customer/${customerId}`
		});
	});
};