import { InfiniteQueryPageContext, PaginatedResponseModel, useHTTP, useHTTPQueryWithCursor } from "shared/lib/http";
import { useUserAuthState } from "src/features/auth/UserAuthState";
import { API_BASE_URL } from "src/lib/config";

export interface ListManualInvoicesResponseModel {
	readonly invoiceId: string;
	readonly created: string;
	readonly status: InvoiceStatus;
	readonly merchantName: string;
	readonly amount: number;
}

export enum InvoiceStatus {
	Created = "Created",
	Billed = "Billed",
	Paid = "Paid"
}

export const useManualInvoices = () => {
	const { state } = useUserAuthState();
	const http = useHTTP(state.accessToken);

	return useHTTPQueryWithCursor(["manualInvoices"], ({ pageParam = 0 }: InfiniteQueryPageContext) => {
		return http<PaginatedResponseModel<ListManualInvoicesResponseModel>>({
			method: "GET",
			url: `${API_BASE_URL}/admin/manual-invoice?cursor=${pageParam}`
		});
	});
};
