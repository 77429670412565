import React from "react";
import styles from "./TextEntry.module.scss";

export interface TextEntryProps {
	value: string;
	onChange: (value: string) => void;
	onEnter?: () => void;
	placeholder?: string;
	inputMode?: "search" | "text" | "none" | "tel" | "url" | "email" | "numeric" | "decimal";
}

export const TextEntry = (props: TextEntryProps): JSX.Element => {
	const {
		value,
		onChange,
		onEnter,
		inputMode,
		placeholder
	} = props;

	const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter" && onEnter) {
			onEnter();
		}
	};

	return (
		<input
			className={styles.textEntry}
			value={value}
			placeholder={placeholder}
			inputMode={inputMode}
			onKeyUp={onKeyUp}
			onChange={e => onChange(e.target.value)} />
	);
};
