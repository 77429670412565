import React from "react";
import styles from "./Container.module.scss";

export interface ContainerProps {
	children: React.ReactNode;
}

export const Container = ({ children }: ContainerProps): JSX.Element => {
	return (
		<div className={styles.container}>
			{children}
		</div>
	);
};
