import React from "react";
import styles from "./ErrorDisplay.module.scss";
import errorBubble from "../assets/error-bubble.svg";
import { ProblemResponse } from "../lib/http";

export type ErrorDisplayProps = {
	readonly message: string | undefined;
} | {
	readonly problemResponse: ProblemResponse | undefined;
}

export const ErrorDisplay = (props: ErrorDisplayProps): JSX.Element => {
	const text = "message" in props
		? props.message
		: props.problemResponse?.body?.detail;

	return (
		<>
			{
				text &&
				<div className={styles.errorDisplay}>
					<img src={errorBubble} />
					{text}
				</div>
			}
		</>
	);
};
