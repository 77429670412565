import React from "react";
import styles from "./ToggleSwitch.module.scss";

export interface ToggleSwitchProps {
	value: boolean;
	onChange: (value: boolean) => void;
	isLoading?: boolean;
}

export const ToggleSwitch = ({ value, onChange, isLoading }: ToggleSwitchProps): JSX.Element => {
	const classNames = [ styles.toggleSwitch ];

	if (value) {
		classNames.push(styles.enabled);
	}

	if (isLoading) {
		classNames.push(styles.loading);
	}

	const onChangeEnriched = () => {
		if (isLoading) {
			return;
		}

		onChange(!value);
	};

	return (
		<div className={classNames.join(" ")} onClick={onChangeEnriched}>
			<div><div></div></div>
		</div>
	);
};
