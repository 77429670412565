import React, { useEffect, useRef } from "react";
import { UseInfiniteQueryResult } from "react-query";
import { PaginatedResponseModel, ProblemResponse } from "shared/lib/http";

export interface InfiniteScrollerProps<T> {
	forQuery: UseInfiniteQueryResult<PaginatedResponseModel<T>, ProblemResponse>;
}

export const InfiniteScroller = <T,>({ forQuery }: InfiniteScrollerProps<T>): JSX.Element => {
	const ref = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		let hasCalledForNextPage = false;

		const windowScrollHandler = () => {
			if (!ref.current || forQuery.isFetchingNextPage || hasCalledForNextPage) {
				return;
			}

			const elementBounds = ref.current.getBoundingClientRect();
			const portionVisible = Math.min(window.innerHeight - elementBounds.y, elementBounds.height) / elementBounds.height;
			const isVisible = portionVisible > 0.2;

			if (isVisible && forQuery.hasNextPage) {
				hasCalledForNextPage = true;
				forQuery.fetchNextPage();
			}
		};

		window.addEventListener("scroll", windowScrollHandler);
		windowScrollHandler();

		return () => window.removeEventListener("scroll", windowScrollHandler);
	}, [forQuery.hasNextPage, forQuery.fetchNextPage, forQuery.data]);

	if (!forQuery.hasNextPage) {
		return <></>;
	}

	return (
		<div ref={ref} style={{minHeight: "32px"}}></div>
	);
};
