import React from "react";
import styles from "./Tag.module.scss";

export interface TagProps {
	text: string;
	color: TagColor;
}

export enum TagColor {
	Disabled = "#463b63",
	Bright = "#967ed3"
}

export const Tag = ({ text, color }: TagProps): JSX.Element => {
	const style = {
		backgroundColor: color
	};

	return (
		<div className={styles.tag} style={style}>
			{text}
		</div>
	);
};
