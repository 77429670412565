import React from "react";
import styles from "./OverflowEllipsis.module.scss";

export interface OverflowEllipsisProps {
	children: React.ReactNode;
}

/**
 * Not working properly?  Ensure the direct parent has overflow: hidden;
 */
export const OverflowEllipsis = ({ children }: OverflowEllipsisProps): JSX.Element => {
	return (
		<div className={styles.overflowEllipsis}>{children}</div>
	);
};
