import React from "react";
import styles from "./Loader.module.scss";
import { useCobrandingSettings } from "../lib/theme";

export interface LoaderProps {
	size?: LoaderSize;
	style?: LoaderStyle;
}

export enum LoaderSize {
	Small,
	Standard,
	Large
}

export enum LoaderStyle {
	Standard,
	Alternate
}

export const Loader = (props: LoaderProps): JSX.Element => {
	const {
		size = LoaderSize.Standard,
		style = LoaderStyle.Standard
	} = props;

	const { enableCobranding, brandColor } = useCobrandingSettings();

	const cssProperties = getStyleForLoaderSize(size);
	cssProperties.borderTopColor = enableCobranding ? brandColor : "#5118EE";

	const classNames = [ styles.loader ];
	if (style === LoaderStyle.Alternate) {
		classNames.push(styles.alternate);
	}

	return (
		<div className={classNames.join(" ")} style={cssProperties}></div>
	);
};

const getStyleForLoaderSize = (size: LoaderSize): React.CSSProperties => {
	switch (size) {
	case LoaderSize.Small:
		return {
			width: "16px",
			height: "16px",
			borderWidth: "2px",
			borderRadius: "10px"
		};
	case LoaderSize.Standard:
		return {
			width: "32px",
			height: "32px",
			borderWidth: "3px",
			borderRadius: "19px"
		};
	case LoaderSize.Large:
		return {
			width: "48px",
			height: "48px",
			borderWidth: "4px",
			borderRadius: "28px"
		};
	}
};
