import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Router } from "./Router";
import "./styles/base.scss";
import "./styles/vars.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import { UserAuthStateProvider } from "./features/auth/UserAuthState";
import { SharedConfigProvider } from "shared/lib/config";
import { API_BASE_URL } from "./lib/config";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 30 * 1000
		}
	}
});

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

const sharedConfig = {
	apiBaseUrl: API_BASE_URL
};

root.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<UserAuthStateProvider>
				<SharedConfigProvider sharedConfig={sharedConfig}>
					<BrowserRouter>
						<Router />
					</BrowserRouter>
				</SharedConfigProvider>
			</UserAuthStateProvider>
		</QueryClientProvider>
	</React.StrictMode>
);
