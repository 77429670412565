import { useCallback } from "react";
import { Problem } from "./Problem";
import { ProblemResponse } from "./ProblemResponse";
import { Request } from "./Request";
import { Response } from "./Response";

export type HTTPFunc = <T = undefined, U = undefined>(request: Request<U>, forcedAccessToken?: string) => Promise<Response<T>>;

export const useHTTP = (accessToken?: string) => {
	return useCallback(createHTTPFunc(accessToken), [accessToken]);
};

const createHTTPFunc = (accessToken?: string): HTTPFunc => async <T = undefined, U = undefined>(request: Request<U>, forcedAccessToken?: string): Promise<Response<T>> => {
	const headers = { ...request.headers };
	headers["Content-Type"] = "application/json";

	if (forcedAccessToken || accessToken) {
		headers["Authorization"] = `Bearer ${forcedAccessToken || accessToken}`;
	}

	try {
		const response = await fetch(request.url, {
			method: request.method,
			headers,
			mode: "cors",
			cache: "no-cache",
			body: "body" in request
				? JSON.stringify(request.body)
				: undefined
		});

		if (response.status > 299 || response.status < 200) {
			throw await getProblemResponse(response);
		}

		const body = hasJSONBody(response)
			? await response.json() as T
			: undefined;

		return {
			statusCode: response.status,
			headers: getHeaders(response.headers),
			body: (body as T extends undefined ? never : T)
		};
	} catch (err: unknown) {
		if ((err as ProblemResponse).body) {
			throw err;
		}

		const problem: Problem = {
			type: "NetworkError",
			detail: "A network failure caused the operation to fail."
		};

		throw {
			statusCode: 0,
			body: problem
		};
	}
};

export const defaultHTTP = createHTTPFunc();

export const getProblemResponse = async (response: globalThis.Response): Promise<ProblemResponse> => {
	let problem: Problem;
	try {
		problem = await response.json();
	} catch {
		problem = {
			type: `Status${response.status}`,
			detail: `The operation failed due to a ${response.status} status.`
		};
	}

	if (!problem.type) {
		problem = {
			...problem,
			type: `Status${response.status}`
		};
	}

	if (!problem.detail) {
		problem = {
			...problem,
			detail: `The operation failed due to a ${response.status} status.`
		};
	}

	return {
		statusCode: response.status,
		body: problem
	};
};

const hasJSONBody = (response: globalThis.Response): boolean => {
	const contentType = response.headers.get("Content-Type");
	return !!contentType && contentType.indexOf("application/json") === 0;
};

export const getHeaders = (headers: Headers): Record<string, string> => {
	const result: Record<string, string> = { };

	headers.forEach((value, name) => {
		result[name] = value;
	});

	return result;
};
