import React, { useMemo, useState } from "react";
import searchIcon from "src/assets/search.svg";
import { ContainerSegment } from "src/lib/components/ContainerSegment";
import { Container } from "src/lib/components/Container";
import { Limit } from "src/lib/components/Limit";
import { TextEntry } from "src/lib/components/TextEntry";
import { TitleBar } from "src/lib/components/TitleBar";
import { Stack } from "shared/components";
import { ContainerListButton } from "src/lib/components/ContainerListButton";
import { useNavigate } from "react-router-dom";
import { useTitle } from "src/lib/useTitle";

export const PageCustomerSearch = (): JSX.Element => {
	useTitle("Customer Search");

	const [emailAddressText, setEmailAddressText] = useState("");
	const emailAddress = useMemo(() => emailAddressText.trim().toLowerCase(), [emailAddressText]);
	const navigate = useNavigate();

	const onSearch = () => navigate(`/customers/${emailAddress}`);

	return (
		<Limit>
			<TitleBar title="Customer Search" />

			<Stack>
				<Container>
					<ContainerSegment>
						We currently only allow looking up customers by their exact
						email address.  Please provide one below to look up.
					</ContainerSegment>
					<ContainerSegment>
						<TextEntry
							inputMode="email"
							placeholder="Email Address"
							onEnter={onSearch}
							value={emailAddressText}
							onChange={setEmailAddressText} />
					</ContainerSegment>
				</Container>

				<Container>
					<ContainerListButton
						icon={searchIcon}
						disabled={!emailAddress}
						text="Search"
						onClick={onSearch} />
				</Container>
			</Stack>
		</Limit>
	);
};
