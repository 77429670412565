import React, { useEffect, useState } from "react";
import styles from "./ModalBase.module.scss";

export interface ModalBaseProps {
	visible: boolean;
	children: React.ReactNode;
}

export const ModalBase = ({ visible, children }: ModalBaseProps): JSX.Element => {
	const [hasVisibleClass, setHasVisibleClass] = useState(false);
	const [hasDisplayedClass, setHasDisplayedClass] = useState(false);

	useEffect(() => {
		if (visible) {
			setHasDisplayedClass(true);

			const timeout = setTimeout(() => setHasVisibleClass(true), 20);
			return () => clearTimeout(timeout);
		} else {
			setHasVisibleClass(false);

			const timeout = setTimeout(() => setHasDisplayedClass(false), 400);
			return () => clearTimeout(timeout);
		}
	}, [visible]);

	const classNames = [ styles.modalBase ];
	if (hasVisibleClass) {
		classNames.push(styles.visible);
	}
	if (hasDisplayedClass) {
		classNames.push(styles.displayed);
	}

	return (
		<div className={classNames.join(" ")}>
			<div></div>
			<div className={styles.outerContent}>
				<div></div>
				<div className={styles.innerContent}>{children}</div>
				<div></div>
			</div>
			<div></div>
		</div>
	);
};
