import React from "react";
import styles from "./TitleBar.module.scss";

export interface TitleBarProps {
	title: string;
}

export const TitleBar = ({ title }: TitleBarProps): JSX.Element => {
	return (
		<div className={styles.titleBar}>
			{title}
		</div>
	);
};
