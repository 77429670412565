import { useHTTP, useHTTPQuery } from "shared/lib/http";
import { useUserAuthState } from "src/features/auth/UserAuthState";
import { API_BASE_URL } from "src/lib/config";

export interface GetPendingRedemptionsResponseModel {
	readonly redemptionId: string;
	readonly created: string;
	readonly merchantName: string;
	readonly refundPeriodEnd: string;
}

export const usePendingRedemptions = (emailAddress: string) => {
	const {state} = useUserAuthState();
	const http = useHTTP(state.accessToken);

	return useHTTPQuery(["pendingRedemptions", emailAddress], () => {
		return http<GetPendingRedemptionsResponseModel[]>({
			method: "GET",
			url: `${API_BASE_URL}/admin/customer/pending-redemptions?emailAddress=${emailAddress}`
		});
	});
};
