import React from "react";
import styles from "./ContainerSegment.module.scss";

export interface ContainerSegmentProps {
	children: React.ReactNode;
}

export const ContainerSegment = ({ children }: ContainerSegmentProps): JSX.Element => {
	return (
		<div className={styles.containerSegment}>
			{children}
		</div>
	);
};
