import { useCallback } from "react";
import { createGlobalState } from "shared/lib/state";
import { StorageService } from "shared/lib/storage";
import { TokenClaims } from "shared/lib/auth";

export interface UserAuthState {
	readonly accessToken?: string;
	readonly idToken?: string;
}

enum UserAuthActionType {
	Unknown,
	Login,
	Logout
}

type UserAuthAction = {
	type: UserAuthActionType.Login;
	accessToken: string;
	idToken: string;
} | {
	type: UserAuthActionType.Logout;
};

const getInitialState = (): UserAuthState => {
	const accessToken = StorageService.getPersistent<string>("UserAccessToken");
	const idToken = StorageService.getPersistent<string>("UserIdToken");

	if (!accessToken || !idToken) {
		return { };
	}

	const accessTokenClaims = new TokenClaims(accessToken);
	const idTokenClaims = new TokenClaims(idToken);

	if (!accessTokenClaims.withinExpiryPeriod() || !idTokenClaims.withinExpiryPeriod()) {
		StorageService.removePersistent("UserAccessToken");
		StorageService.removePersistent("UserIdToken");
		return { };
	}

	return {
		accessToken,
		idToken
	};
};

const reducer = (state: UserAuthState, action: UserAuthAction): UserAuthState => {
	switch (action.type) {
	case UserAuthActionType.Login: {
		return {
			...state,
			accessToken: action.accessToken,
			idToken: action.idToken
		};
	}
	case UserAuthActionType.Logout:
		return {
			...state,
			accessToken: undefined,
			idToken: undefined
		};
	}
};

const [UserAuthStateProvider, useUserAuthStateReducer] = createGlobalState(reducer, getInitialState());

export const useUserAuthState = () => {
	const [state, dispatch] = useUserAuthStateReducer();

	const dispatchLogin = useCallback((accessToken: string, idToken: string) => {
		dispatch({
			type: UserAuthActionType.Login,
			accessToken,
			idToken
		});

		StorageService.setPersistent("UserAccessToken", accessToken);
		StorageService.setPersistent("UserIdToken", idToken);
	}, [dispatch]);

	const dispatchLogout = useCallback(() => {
		dispatch({ type: UserAuthActionType.Logout });
		StorageService.removePersistent("UserAccessToken");
		StorageService.removePersistent("UserIdToken");
	}, [dispatch]);

	return {
		state,
		dispatchLogin,
		dispatchLogout
	};
};

export { UserAuthStateProvider };
