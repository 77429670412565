import { InfiniteQueryPageContext, PaginatedResponseModel, useHTTP, useHTTPQueryWithCursor } from "shared/lib/http";
import { useUserAuthState } from "src/features/auth/UserAuthState";
import { API_BASE_URL } from "src/lib/config";

export interface CustomerTransactionResponseModel {
	readonly batchId: string;
	readonly created: string;
	readonly merchantName: string;
	readonly redemptionId: string;
	readonly operation: string;
	readonly amount: number;
	readonly pendingBalance: number;
	readonly availableBalance: number;
}

export const useCustomerTransactions = (emailAddress: string) => {
	const { state } = useUserAuthState();
	const http = useHTTP(state.accessToken);

	return useHTTPQueryWithCursor(["customerTransactions", emailAddress], ({ pageParam = 0 }: InfiniteQueryPageContext) => {
		return http<PaginatedResponseModel<CustomerTransactionResponseModel>>({
			method: "GET",
			url: `${API_BASE_URL}/admin/customer/transactions?emailAddress=${emailAddress}&cursor=${pageParam}`
		});
	});
};
