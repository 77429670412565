import React, { useState } from "react";
import { Center, ErrorDisplay, Loader, Stack } from "shared/components";
import mergeIcon from "src/assets/merge.svg";
import { Container } from "src/lib/components/Container";
import { ContainerListButton } from "src/lib/components/ContainerListButton";
import { ContainerListItem } from "src/lib/components/ContainerListItem";
import { ContainerSegment } from "src/lib/components/ContainerSegment";
import { Limit } from "src/lib/components/Limit";
import { TextEntry } from "src/lib/components/TextEntry";
import { TitleBar } from "src/lib/components/TitleBar";
import { useMergeCustomers } from "../api/useMergeCustomers";

export const PageCustomerMerge = (): JSX.Element => {
	const [primaryEmailAddress, setPrimaryEmailAddress] = useState("");
	const [secondaryEmailAddress, setSecondaryEmailAddress] = useState("");
	const mergeCustomers = useMergeCustomers();

	const onClick = () => mergeCustomers.mutate({
		primaryEmailAddress,
		secondaryEmailAddress
	});

	return (
		<Limit>
			<TitleBar title="Merge Customers" />

			{
				!mergeCustomers.isSuccess &&
				<Stack gap={32}>
					<Container>
						<ContainerListItem title="Primary Email Address" subtitle="The one that will remain active">
							<></>
						</ContainerListItem>

						<ContainerSegment>
							<TextEntry
								inputMode="email"
								placeholder="Primary Email Address"
								value={primaryEmailAddress}
								onChange={setPrimaryEmailAddress} />
						</ContainerSegment>
					</Container>

					<Container>
						<ContainerListItem title="Secondary Email Address" subtitle="The one that will be consumed in the merge">
							<></>
						</ContainerListItem>

						<ContainerSegment>
							<TextEntry
								inputMode="email"
								placeholder="Secondary Email Address"
								value={secondaryEmailAddress}
								onChange={setSecondaryEmailAddress} />
						</ContainerSegment>
					</Container>

					{
						!mergeCustomers.isLoading &&
						<Container>
							<ContainerListButton text="Perform Merge" icon={mergeIcon} onClick={onClick} />

							{
								mergeCustomers.error &&
								<ContainerSegment>
									<ErrorDisplay problemResponse={mergeCustomers.error} />
								</ContainerSegment>
							}
						</Container>
					}

					{
						mergeCustomers.isLoading &&
						<Center><Loader /></Center>
					}
				</Stack>
			}

			{
				mergeCustomers.isSuccess &&
				<Container>
					<ContainerSegment>
						✅ The customer merge has been queued for processing.
					</ContainerSegment>
				</Container>
			}
		</Limit>
	);
};
