import React from "react";
import styles from "./Box.module.scss";

export interface BoxProps {
	children: React.ReactNode;
	title: string;
}

export const Box = ({ children, title }: BoxProps): JSX.Element => {

	return (
		<div className={styles.Box}>
			<h3>{title}:</h3>
			{children}
		</div>
	);
};