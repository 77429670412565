import React from "react";
import styles from "./DetailsContainer.module.scss";

export interface DetailsContainerProps {
	children: React.ReactNode;
}

export const DetailsContainer = ({ children }: DetailsContainerProps): JSX.Element => {
	return (
		<div className={styles.details_container}>
			{children}
		</div>
	);
};