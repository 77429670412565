import { MutationFunction, useQueryClient } from "react-query";
import { Response, useHTTP, useHTTPMutation } from "shared/lib/http";
import { useUserAuthState } from "src/features/auth/UserAuthState";
import { API_BASE_URL } from "src/lib/config";
import { LegacyScriptTagConfiguration } from "./models/LegacyScriptTagConfiguration";

export interface SetLegacyScriptTagsOptions {
	merchantId: string;
	requestModel: SetLegacyScriptTagsRequestModel;
}

export interface SetLegacyScriptTagsRequestModel {
	readonly scriptTagConfiguration: LegacyScriptTagConfiguration;
}

export const useSetLegacyScriptTags = () => {
	const queryClient = useQueryClient();
	const { state } = useUserAuthState();
	const http = useHTTP(state.accessToken);

	const mutFunc: MutationFunction<Response<undefined>, SetLegacyScriptTagsOptions> = (options: SetLegacyScriptTagsOptions) => {
		return http<undefined, SetLegacyScriptTagsRequestModel>({
			method: "POST",
			url: `${API_BASE_URL}/admin/merchant/${options.merchantId}/legacy-script-tags`,
			body: options.requestModel
		});
	};

	return useHTTPMutation(mutFunc, {
		onSuccess: (_, options) => {
			queryClient.invalidateQueries(["merchant", options.merchantId]);
		}
	});
};
