import React from "react";
import styles from "./ContainerListItem.module.scss";
import { Link } from "react-router-dom";

export interface ContainerListItemProps {
	title: string;
	subtitle?: string;
	children?: React.ReactNode;
	link?: string;
	onClick?: () => void;
}

export const ContainerListItem = ({ title, subtitle, children, link, onClick }: ContainerListItemProps): JSX.Element => {
	const classNames = [styles.containerListItem];
	if (onClick || link) {
		classNames.push(styles.clickable);
	}

	const contents = (
		<>
			<div>
				<div>{title}</div>
				{subtitle && <div>{subtitle}</div>}
			</div>
			<div className={styles.gap}></div>
			<div>{children}</div>
		</>
	);

	if (link) {
		return (
			<Link to={link} onClick={onClick} className={classNames.join(" ")}>
				{contents}
			</Link>
		);
	}

	return (
		<div className={classNames.join(" ")} onClick={onClick}>
			{contents}
		</div>
	);
};
