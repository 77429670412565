import dayjs from "dayjs";
import { CustomerPerformanceRecordOrder } from "shared/lib/domain";
import { InfiniteQueryPageContext, PaginatedResponseModel, useHTTP, useHTTPQueryWithCursor } from "shared/lib/http";
import { useUserAuthState } from "src/features/auth/UserAuthState";
import { API_BASE_URL } from "src/lib/config";

export interface GetCustomerPerformanceReportResponseModel extends PaginatedResponseModel<GetCustomerPerformanceReportItemResponseModel> {
	totals: CustomerPerformanceReportTotals | null;
}

export interface CustomerPerformanceReportTotals {
	totalOrders: number;
	totalShares: number;
	totalClicks: number;
	totalRedemptions: number;
	totalOrderValue: number;
}

export interface GetCustomerPerformanceReportItemResponseModel {
	readonly integrationCustomerId?: string;
	readonly customerId: string;
	readonly customerName: string;
	readonly email?: string;
	readonly totalClicks: number;
	readonly totalOrders: number;
	readonly totalShares: number;
	readonly totalRedemptions: number;
}

export const useCustomerPerformanceReport = (merchantId: string, from: Date, to: Date, rewardConfigurationType: string | null, sortOrder: CustomerPerformanceRecordOrder) => {
	const { state } = useUserAuthState();
	const http = useHTTP(state.accessToken);

	const formattedFrom = dayjs(from).format("YYYY-MM-DD");
	const formattedTo = dayjs(to).format("YYYY-MM-DD");

	return useHTTPQueryWithCursor<GetCustomerPerformanceReportItemResponseModel, GetCustomerPerformanceReportResponseModel>(["customerPerformanceReport", merchantId, formattedFrom, formattedTo, rewardConfigurationType, sortOrder], ({ pageParam = 0 }: InfiniteQueryPageContext) => {
		let url = `${API_BASE_URL}/admin/merchant/${merchantId}/customer-performance-report?cursor=${pageParam}&from=${formattedFrom}&to=${formattedTo}&order=${sortOrder}`;
		if (rewardConfigurationType) {
			url += `&rewardConfigurationType=${rewardConfigurationType}`;
		}

		return http<PaginatedResponseModel<GetCustomerPerformanceReportItemResponseModel>>({
			method: "GET",
			url
		});
	});
};
