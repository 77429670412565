import dayjs from "dayjs";
import { Base64 } from "js-base64";

export class TokenClaims {
	[key: string]: unknown;

	constructor(token: string) {
		const segments = token.split(".", 3);
		if (segments.length !== 3 && segments.length !== 2) {
			throw new Error("invalid token");
		}

		const isTokenWithoutHeader = segments.length === 2;

		const rawJSON = Base64.decode(segments[isTokenWithoutHeader ? 0 : 1]);
		const obj: Record<string, unknown> = JSON.parse(rawJSON);

		for (const x of Object.keys(obj)) {
			this[x] = obj[x];
		}
	}

	public withinExpiryPeriod(): boolean {
		if (!this.exp || typeof this.exp !== "number") {
			return false;
		}

		const now = dayjs();
		const expiryDate = dayjs.unix(this.exp);

		if (now.isAfter(expiryDate)) {
			return false;
		}

		return true;
	}
}
