import React, { useContext, useReducer } from "react";

export interface GlobalStateComponentProps {
	readonly children: React.ReactNode;
}

export const createGlobalState = <S, A>(reducer: React.Reducer<S, A>, initialState: S): [(props: GlobalStateComponentProps) => JSX.Element, () => [S, React.Dispatch<A>]] => {
	const defaultDispatch: React.Dispatch<A> = () => undefined;
	const initialValue: [S, React.Dispatch<A>] = [initialState, defaultDispatch];
	const ctx = React.createContext(initialValue);

	const StateProviderComponent = ({ children }: GlobalStateComponentProps): JSX.Element => {
		const [state, dispatch] = useReducer(reducer, initialState);
		return (
			<ctx.Provider value={[state, dispatch]}>
				{children}
			</ctx.Provider>
		);
	};

	const useStateContext = () => useContext(ctx);

	return [StateProviderComponent, useStateContext];
};
