export class StorageService {

	private static _localStorage = (document.defaultView || window).localStorage;
	private static _sessionStorage = (document.defaultView || window).sessionStorage;

	public static setPersistent<T>(key: string, value: T): void {
		StorageService.set(StorageService._localStorage, key, value);
	}

	public static setTemporary<T>(key: string, value: T): void {
		StorageService.set(StorageService._sessionStorage, key, value);
	}

	public static getPersistent<T>(key: string): T | undefined {
		return StorageService.get<T>(StorageService._localStorage, key);
	}

	public static getTemporary<T>(key: string): T | undefined {
		return StorageService.get<T>(StorageService._sessionStorage, key);
	}

	public static hasPersistent(key: string): boolean {
		return StorageService.has(StorageService._localStorage, key);
	}

	public static hasTemporary(key: string): boolean {
		return StorageService.has(StorageService._sessionStorage, key);
	}

	public static removePersistent(key: string): void {
		return StorageService.remove(StorageService._localStorage, key);
	}

	public static removeTemporary(key: string): void {
		return StorageService.remove(StorageService._sessionStorage, key);
	}

	public static getOrAddPersistent<T>(key: string, valueFunc: () => T): T {
		return StorageService.getOrAdd(StorageService._localStorage, key, valueFunc);
	}

	public static getOrAddTemporary<T>(key: string, valueFunc: () => T): T {
		return StorageService.getOrAdd(StorageService._sessionStorage, key, valueFunc);
	}

	private static set<T>(storage: Storage, key: string, value: T): void {
		storage.setItem(`rave_${key}`, JSON.stringify(value));
	}

	private static get<T>(storage: Storage, key: string): T | undefined {
		const json = storage.getItem(`rave_${key}`);
		if (!json) {
			return undefined;
		}

		return <T>JSON.parse(json);
	}

	private static has(storage: Storage, key: string): boolean {
		return !!storage.getItem(`rave_${key}`);
	}

	private static remove(storage: Storage, key: string): void {
		storage.removeItem(`rave_${key}`);
	}

	private static getOrAdd<T>(storage: Storage, key: string, valueFunc: () => T): T {
		try {
			const existingValue = StorageService.get<T>(storage, key);
			if (existingValue) {
				return existingValue;
			}

			const value = valueFunc();
			StorageService.set(storage, key, value);
			return value;
		} catch {
			return valueFunc();
		}
	}
}
