import React from "react";
import styles from "./AdminModal.module.scss";
import close from "src/assets/close.svg";
import { ModalBase } from "shared/components";

export interface AdminModalProps {
	title: string;
	visible: boolean;
	onCancel: () => void;
	children: React.ReactNode;
}

export const AdminModal = ({ title, visible, onCancel, children }: AdminModalProps): JSX.Element => {
	return (
		<ModalBase visible={visible}>
			<div className={styles.adminModal}>
				<div>
					<div>{title}</div>
					<img src={close} onClick={onCancel} />
				</div>
				<div>
					{children}
				</div>
			</div>
		</ModalBase>
	);
};
