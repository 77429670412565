import { MutationFunction } from "react-query";
import { Response, useHTTP, useHTTPMutation } from "shared/lib/http";
import { useUserAuthState } from "src/features/auth/UserAuthState";
import { API_BASE_URL } from "src/lib/config";

export interface MergeCustomersRequestModel {
	readonly primaryEmailAddress: string;
	readonly secondaryEmailAddress: string;
}

export const useMergeCustomers = () => {
	const { state } = useUserAuthState();
	const http = useHTTP(state.accessToken);

	const mutFunc: MutationFunction<Response<undefined>, MergeCustomersRequestModel> = requestModel => {
		return http<undefined, MergeCustomersRequestModel>({
			method: "POST",
			url: `${API_BASE_URL}/admin/customer/merge`,
			body: requestModel
		});
	};

	return useHTTPMutation(mutFunc);
};
