import { InfiniteQueryPageContext, PaginatedResponseModel, useHTTP, useHTTPQueryWithCursor } from "shared/lib/http";
import { useUserAuthState } from "src/features/auth/UserAuthState";
import { API_BASE_URL } from "src/lib/config";
import { UgcSubmissionResponseModel } from "./models/UgcSubmissionResponseModel";

export const useUgcSubmissions = (merchantId: string | null) => {
	const { state } = useUserAuthState();
	const http = useHTTP(state.accessToken);

	return useHTTPQueryWithCursor(["ugcSubmissions"], ({ pageParam = 0 }: InfiniteQueryPageContext) => {
		return http<PaginatedResponseModel<UgcSubmissionResponseModel>>({
			method: "GET",
			url: `${API_BASE_URL}/admin/ugc/submissions?cursor=${pageParam}&merchantId=${merchantId ?? ""}`
		});
	});
};
