import React from "react";
import styles from "./SubHeader.module.scss";

export interface SubHeaderProps {
	subHeader: string;
}

export const SubHeader = ({ subHeader }: SubHeaderProps): JSX.Element => {
	return (
		<div className={styles.subHeader}>
			{subHeader}
		</div>
	);
};
