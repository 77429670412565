import React from "react";
import { useEffectOnFirstMount } from "shared/hooks";
import { StorageService } from "shared/lib/storage";
import { ADMIN_BASE_URL, AD_ADMIN_API_AUDIENCE, AD_ADMIN_APP_CLIENT_ID, ARM_TENANT_ID } from "src/lib/config";
import { generatePKCECodeVerifierAndChallenge, generateRandomStateToken } from "shared/lib/crypto";
import { Center, Loader, LoaderSize } from "shared/components";
import { useTitle } from "src/lib/useTitle";

export const PageLoginInitiate = (): JSX.Element => {
	useTitle("Logging in...");

	useEffectOnFirstMount(() => {
		generatePKCECodeVerifierAndChallenge().then(pkce => {
			const stateToken = generateRandomStateToken(32);
			const nonce = generateRandomStateToken(16);

			let redirectUrl = `https://login.microsoftonline.com/${ARM_TENANT_ID}/oauth2/v2.0/authorize`;
			redirectUrl += `?client_id=${AD_ADMIN_APP_CLIENT_ID}`;
			redirectUrl += "&response_type=code id_token";
			redirectUrl += `&redirect_uri=${ADMIN_BASE_URL}/login/callback`;
			redirectUrl += "&response_mode=fragment";
			redirectUrl += `&scope=openid ${AD_ADMIN_API_AUDIENCE}/.default`;
			redirectUrl += `&state=${stateToken}`;
			redirectUrl += `&nonce=${nonce}`;
			redirectUrl += `&code_challenge=${pkce.challenge}`;
			redirectUrl += "&code_challenge_method=S256";

			StorageService.setTemporary("AdminStateToken", stateToken);
			StorageService.setTemporary("AdminCodeVerifier", pkce.verifier);
			window.location.replace(redirectUrl);
		});
	});

	return (
		<Center>
			<Loader size={LoaderSize.Large} />
		</Center>
	);
};
