import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { TitleBar } from "src/lib/components/TitleBar";
import { useCustomerTransactions } from "../api/useCustomerTransactions";
import { StyledTable } from "src/lib/components/StyledTable";
import { StyledTableHeader } from "src/lib/components/StyledTableHeader";
import { Container } from "src/lib/components/Container";
import { Center, ErrorDisplay, InfiniteScroller, Loader, MoneyDisplay, Stack } from "shared/components";
import dayjs from "dayjs";
import { ContainerSegment } from "src/lib/components/ContainerSegment";
import { usePendingRedemptions } from "../api/usePendingRedemptions";
import { TextEntry } from "src/lib/components/TextEntry";
import { ContainerListButton } from "src/lib/components/ContainerListButton";
import { useManualDisbursement } from "../api/useManualDisbursement";
import disburseIcon from "src/assets/disburse.svg";
import { useTitle } from "shared/hooks";

export const PageCustomer = (): JSX.Element => {
	const params = useParams();
	const emailAddress = params.emailAddress as string;
	useTitle(`Customer ${emailAddress}`);

	const pendingRedemptions = usePendingRedemptions(emailAddress);
	const transactions = useCustomerTransactions(emailAddress);
	const manualDisbursement = useManualDisbursement();
	const [manualDisbursementAmountText, setManualDisbursementAmountText] = useState("");
	const manualDisbursementAmount = useMemo(() => {
		const parsedValue = parseFloat(manualDisbursementAmountText);
		if (isNaN(parsedValue)) {
			return 0;
		}

		return Math.round(parsedValue * 100) / 100;
	}, [manualDisbursementAmountText]);

	const onDisburse = () => manualDisbursement.mutate({
		emailAddress,
		amount: manualDisbursementAmount
	});

	return (
		<>
			<TitleBar title={emailAddress} />

			<Stack>

				<Container>
					<ContainerSegment>
						<b>Pending Redemptions</b><br />
						These Redemptions are not outside their refund period,
						so any rewards they have earned are not yet available.
						A lack of redemptions here does not mean the customer
						has no rewards pending as we may have not received
						payment from the merchant yet.
					</ContainerSegment>

					{
						pendingRedemptions.error &&
						<ContainerSegment>
							<ErrorDisplay problemResponse={pendingRedemptions.error} />
						</ContainerSegment>
					}

					{
						pendingRedemptions.isLoading &&
						<ContainerSegment>
							<Center><Loader /></Center>
						</ContainerSegment>
					}

					{
						pendingRedemptions.data &&
						<StyledTable>
							<thead>
								<tr>
									<StyledTableHeader>Date</StyledTableHeader>
									<StyledTableHeader>RedemptionId</StyledTableHeader>
									<StyledTableHeader>Merchant</StyledTableHeader>
									<StyledTableHeader>Refund Period End</StyledTableHeader>
								</tr>
							</thead>
							<tbody>
								{
									pendingRedemptions.data.map(x => (
										<tr key={x.redemptionId}>
											<td>{dayjs(x.created).format("DD MMM YYYY")}</td>
											<td>{x.redemptionId}</td>
											<td>{x.merchantName}</td>
											<td>{dayjs(x.refundPeriodEnd).format("DD MMM YYYY")}</td>
										</tr>
									))
								}
							</tbody>
						</StyledTable>
					}
				</Container>

				<Container>
					<ContainerSegment>
						<b>Manual Disbursement</b><br />
						You can manually disburse funds for this customer if
						they have a positive available balance.
					</ContainerSegment>

					<ContainerSegment>
						<TextEntry
							placeholder="Disbursement Amount"
							value={manualDisbursementAmountText}
							onChange={setManualDisbursementAmountText} />
					</ContainerSegment>

					{
						manualDisbursement.error &&
						<ContainerSegment>
							<ErrorDisplay problemResponse={manualDisbursement.error} />
						</ContainerSegment>
					}

					{
						manualDisbursement.isLoading &&
						<ContainerSegment>
							<Center><Loader /></Center>
						</ContainerSegment>
					}

					<ContainerListButton
						icon={disburseIcon}
						disabled={!manualDisbursementAmount || manualDisbursement.isLoading}
						text="Perform Disbursement"
						onClick={onDisburse} />
				</Container>

				<Container>
					<ContainerSegment>
						<b>Transaction History</b><br />
						These are the ledger transactions that pertain to this
						customer, newest first.
					</ContainerSegment>

					{
						transactions.error &&
						<ContainerSegment>
							<ErrorDisplay problemResponse={transactions.error} />
						</ContainerSegment>
					}

					{
						transactions.isLoading &&
						<ContainerSegment>
							<Center><Loader /></Center>
						</ContainerSegment>
					}

					{
						transactions.data &&
						<StyledTable>
							<thead>
								<tr>
									<StyledTableHeader>Date</StyledTableHeader>
									<StyledTableHeader>RedemptionId</StyledTableHeader>
									<StyledTableHeader>Merchant</StyledTableHeader>
									<StyledTableHeader>Operation</StyledTableHeader>
									<StyledTableHeader>Amount</StyledTableHeader>
									<StyledTableHeader>Pending Balance</StyledTableHeader>
									<StyledTableHeader>Available Balance</StyledTableHeader>
								</tr>
							</thead>
							<tbody>
								{
									transactions.data.pages.flatMap(x => x.results).map(x => (
										<tr key={x.batchId}>
											<td>{dayjs(x.created).format("DD MMM YYYY")}</td>
											<td>{x.redemptionId}</td>
											<td>{x.merchantName}</td>
											<td>{x.operation}</td>
											<td><MoneyDisplay amount={x.amount} /></td>
											<td><MoneyDisplay amount={x.pendingBalance} /></td>
											<td><MoneyDisplay amount={x.availableBalance} /></td>
										</tr>
									))
								}
							</tbody>
						</StyledTable>
					}
				</Container>

				<InfiniteScroller forQuery={transactions} />
			</Stack>
		</>
	);
};
