import React from "react";
import styles from "./Limit.module.scss";

export interface LimitProps {
	children: React.ReactNode;
}

export const Limit = ({ children }: LimitProps): JSX.Element => {
	return (
		<div className={styles.limit}>{children}</div>
	);
};
