import React from "react";
import styles from "./FrameUser.module.scss";
import { Outlet, useNavigate } from "react-router-dom";
import { useEffectOnFirstMount } from "shared/hooks";
import { StorageService } from "shared/lib/storage";
import { useUserAuthState } from "../auth/UserAuthState";
import { SideBar } from "src/lib/components/SideBar";

export const FrameUser = (): JSX.Element => {
	const navigate = useNavigate();
	const { state } = useUserAuthState();

	useEffectOnFirstMount(() => {
		if (state.accessToken && state.idToken) {
			return;
		}

		const postLoginDestination = window.location.href.toString().replace(window.location.origin, "");
		StorageService.setTemporary("PostLoginDestination", postLoginDestination);
		navigate("/login/initiate", { replace: true });
	});

	if (!state.accessToken || !state.idToken) {
		return <></>;
	}

	return (
		<div className={styles.frameUser}>
			<div><SideBar /></div>
			<div><Outlet /></div>
		</div>
	);
};
