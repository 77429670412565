import React, { useState } from "react";
import billingCycleIcon from "src/assets/billing-cycle.svg";
import { Link, useParams } from "react-router-dom";
import { Limit } from "src/lib/components/Limit";
import { useMerchant } from "../api/useMerchant";
import { Center, ErrorDisplay, InfiniteScroller, Loader, MoneyDisplay, SmartBox, Stack } from "shared/components";
import { Container } from "src/lib/components/Container";
import { TitleBar } from "src/lib/components/TitleBar";
import { ContainerListItem } from "src/lib/components/ContainerListItem";
import { Tag, TagColor } from "src/lib/components/Tag";
import { useTitle } from "src/lib/useTitle";
import { ToggleSwitch } from "src/lib/components/ToggleSwitch";
import { ContainerSegment } from "src/lib/components/ContainerSegment";
import { GetCustomerPerformanceReportItemResponseModel, useCustomerPerformanceReport } from "../api/useCustomerPerformanceReport";
import dayjs from "dayjs";
import { StyledTable } from "src/lib/components/StyledTable";
import { ContainerControls } from "src/lib/components/ContainerControls";
import { Select } from "src/lib/components/Select";
import { CustomerPerformanceRecordOrder, RewardConfigurationType } from "shared/lib/domain";
import { DateRangeSelector } from "src/lib/components/DateRangeSelector";
import { useSetLegacyScriptTags } from "../api/useSetLegacyScriptTags";
import { LegacyScriptTagConfiguration } from "../api/models/LegacyScriptTagConfiguration";
import { StyledTableHeader } from "src/lib/components/StyledTableHeader";
import { useSetMerchantCommuncation } from "../api/useSetMerchantCommunication";
import { ContainerListButton } from "src/lib/components/ContainerListButton";
import { ALLOW_TEST_FEATURES } from "src/lib/config";
import { useSimulateBillingCycle } from "../api/useSimulateBillingCycle";

export const PageMerchant = (): JSX.Element => {
	const merchantId = useParams()["merchantId"] || "";
	const merchant = useMerchant(merchantId);
	const setLegacyScriptTags = useSetLegacyScriptTags();
	const setMerchantCommunication = useSetMerchantCommuncation();
	const simulateBillingCycle = useSimulateBillingCycle();
	useTitle(merchant.data ? merchant.data.name : "Merchant");

	if (merchant.isLoading || !merchant.data) {
		return (
			<Limit>
				<Center><Loader /></Center>
			</Limit>
		);
	}

	if (merchant.error) {
		return (
			<Limit>
				<Container>
					<ContainerSegment>
						<ErrorDisplay problemResponse={merchant.error} />
					</ContainerSegment>
				</Container>
			</Limit>
		);
	}

	const {
		name,
		domain,
		slug,
		klaviyoConfigured,
		scriptTagConfiguration
	} = merchant.data;

	const setScriptTagConfiguration = (scriptTagConfiguration: LegacyScriptTagConfiguration) => setLegacyScriptTags.mutate({
		merchantId,
		requestModel: {
			scriptTagConfiguration
		}
	});

	const onLegacyScriptTagsEnabledChange = (enabled: boolean) => {
		if (enabled) {
			setScriptTagConfiguration(LegacyScriptTagConfiguration.FloaterAndWidget);
		} else {
			setScriptTagConfiguration(LegacyScriptTagConfiguration.Disabled);
		}
	};

	const onLegacyWidgetChange = (enabled: boolean) => {
		if (enabled) {
			if (scriptTagConfiguration === LegacyScriptTagConfiguration.FloaterOnly) {
				setScriptTagConfiguration(LegacyScriptTagConfiguration.FloaterAndWidget);
			}
			else {
				setScriptTagConfiguration(LegacyScriptTagConfiguration.ModalsAndWidget);
			}
		} else {
			if (scriptTagConfiguration === LegacyScriptTagConfiguration.FloaterAndWidget) {
				setScriptTagConfiguration(LegacyScriptTagConfiguration.FloaterOnly);
			}
			else {
				setScriptTagConfiguration(LegacyScriptTagConfiguration.ModalsOnly);
			}
		}
	};

	const onLegacyDisplayStyleChange = (displayStyle: string) => {
		if (displayStyle === "modals") {
			if (scriptTagConfiguration === LegacyScriptTagConfiguration.FloaterOnly) {
				setScriptTagConfiguration(LegacyScriptTagConfiguration.ModalsOnly);
			}
			else {
				setScriptTagConfiguration(LegacyScriptTagConfiguration.ModalsAndWidget);
			}
		} else {
			if (scriptTagConfiguration === LegacyScriptTagConfiguration.ModalsOnly) {
				setScriptTagConfiguration(LegacyScriptTagConfiguration.FloaterOnly);
			}
			else {
				setScriptTagConfiguration(LegacyScriptTagConfiguration.FloaterAndWidget);
			}
		}
	};

	return (
		<Limit>
			<TitleBar title={name} />
			<Stack gap={32}>
				<Container>
					<ContainerListItem title="Domain">
						{domain}
					</ContainerListItem>

					<ContainerListItem title="Slug">
						{slug}
					</ContainerListItem>

					<ContainerListItem title="Klaviyo">
						<Tag
							text={klaviyoConfigured ? "Configured" : "Not Configured"}
							color={klaviyoConfigured ? TagColor.Bright : TagColor.Disabled} />
					</ContainerListItem>
				</Container>

				<Container>
					<ContainerSegment>
						Some merchants do not use Shopify 2.0 themes and therefore
						cannot enable our scripts in their theme editor.
						We can fall back to the legacy approach of injecting our
						script tags into their theme by configuring the options
						below.
					</ContainerSegment>

					{
						!setLegacyScriptTags.isLoading && !merchant.isFetching &&
						<>
							<ContainerListItem title="Enabled" subtitle="Switch on to use the legacy approach for this merchant">
								<ToggleSwitch value={scriptTagConfiguration !== LegacyScriptTagConfiguration.Disabled} onChange={onLegacyScriptTagsEnabledChange} />
							</ContainerListItem>
							{
								scriptTagConfiguration !== LegacyScriptTagConfiguration.Disabled &&
								<>
									<ContainerListItem title="Widget" subtitle="Switch on to embed the widget for this merchant">
										<ToggleSwitch value={scriptTagConfiguration === LegacyScriptTagConfiguration.ModalsAndWidget || scriptTagConfiguration === LegacyScriptTagConfiguration.FloaterAndWidget} onChange={onLegacyWidgetChange} />
									</ContainerListItem>
									<ContainerListItem title="Display Style" subtitle="How The Rave presents to customers">
										<Select value={scriptTagConfiguration === LegacyScriptTagConfiguration.ModalsOnly || scriptTagConfiguration === LegacyScriptTagConfiguration.ModalsAndWidget ? "modals" : "floater"} onChange={onLegacyDisplayStyleChange} options={[
											{ text: "Modals", value: "modals" },
											{ text: "Floater", value: "floater" }
										]} />
									</ContainerListItem>
								</>
							}
						</>
					}

					{
						(setLegacyScriptTags.isLoading || merchant.isFetching) &&
						<ContainerSegment>
							<Center><Loader /></Center>
						</ContainerSegment>
					}
				</Container>

				<Container>
					<ContainerListItem title="Merchant Communications" subtitle="Enable to allow the rave to communicate with merchant's customers with drip campaigns etc">
						<ToggleSwitch
							value={merchant.data.allowCustomerCommunications}
							onChange={allowCustomerCommunications => setMerchantCommunication.mutate({
								merchantId,
								requestModel: {
									allowCustomerCommunications
								}
							})} />
					</ContainerListItem>
				</Container>

				<Container>
					<ContainerSegment>
						<b>Simulate Billing Cycle</b><br />
						In test environments, you can click the button below to simulate a full billing cycle for the merchant.  This will pay off
						all outstanding invoices and allocate rewards to customers.  Takes around 15 seconds or so.
					</ContainerSegment>

					{
						simulateBillingCycle.isLoading &&
						<ContainerSegment>
							<Center><Loader /></Center>
						</ContainerSegment>
					}

					{
						simulateBillingCycle.error &&
						<ContainerSegment>
							<ErrorDisplay problemResponse={simulateBillingCycle.error} />
						</ContainerSegment>
					}

					{
						simulateBillingCycle.isSuccess &&
						<ContainerSegment>
							✅ Finished!
						</ContainerSegment>
					}

					<ContainerListButton
						onClick={() => simulateBillingCycle.mutate(merchantId)}
						disabled={!ALLOW_TEST_FEATURES} icon={billingCycleIcon}
						text="Simulate Billing Cycle" />
				</Container>

				<CustomerPerformanceReport merchantId={merchantId} />
			</Stack>
		</Limit>
	);
};

interface CustomerPerformanceReportProps {
	merchantId: string;
}

const CustomerPerformanceReport = ({ merchantId }: CustomerPerformanceReportProps): JSX.Element => {
	const [from, setFrom] = useState(dayjs().add(-1, "month").toDate());
	const [to, setTo] = useState(dayjs().toDate());
	const [rewardConfigurationType, setRewardConfigurationType] = useState<RewardConfigurationType | null>(null);
	const [sortOrder, setSortOrder] = useState(CustomerPerformanceRecordOrder.Clicks);
	const customerPerformanceReport = useCustomerPerformanceReport(merchantId, from, to, rewardConfigurationType, sortOrder);

	return (
		<>
			<Stack gap={32}>
				{
					customerPerformanceReport.data && customerPerformanceReport.data.pages.length > 0 && customerPerformanceReport.data.pages[0].totals &&
					<Container>
						<ContainerListItem title="Links Generated">
							{customerPerformanceReport.data.pages[0].totals.totalOrders}
						</ContainerListItem>
						<ContainerListItem title="Total Shares">
							{customerPerformanceReport.data.pages[0].totals.totalShares}
						</ContainerListItem>
						<ContainerListItem title="Total Clicks">
							{customerPerformanceReport.data.pages[0].totals.totalClicks}
						</ContainerListItem>
						<ContainerListItem title="Purchases Driven">
							{customerPerformanceReport.data.pages[0].totals.totalRedemptions}
						</ContainerListItem>
						<ContainerListItem title="Revenue">
							<MoneyDisplay amount={customerPerformanceReport.data.pages[0].totals.totalOrderValue} />
						</ContainerListItem>
					</Container>
				}

				<Container>
					<ContainerControls>
						<DateRangeSelector from={from} to={to} onChangeFrom={setFrom} onChangeTo={setTo} />
						<Select value={rewardConfigurationType} onChange={setRewardConfigurationType} options={[
							{ text: "All", value: null },
							{ text: "Post-Purchase", value: RewardConfigurationType.PostPurchase },
							{ text: "Open Affiliate", value: RewardConfigurationType.Open }
						]} />
					</ContainerControls>
					<SmartBox
						switchOn={customerPerformanceReport}
						loading={() => <ContainerSegment><Center><Loader /></Center></ContainerSegment>}
						error={err => <ContainerSegment><ErrorDisplay problemResponse={err} /></ContainerSegment>}
						success={data => <CustomerPerformanceReportTable merchantId={merchantId} sortOrder={sortOrder} setSortOrder={setSortOrder} rows={data.pages.flatMap(x => x.results)} />} />
				</Container>

				<InfiniteScroller forQuery={customerPerformanceReport} />
			</Stack>
		</>
	);
};

interface CustomerPerformanceReportTableProps {
	rows: GetCustomerPerformanceReportItemResponseModel[];
	sortOrder: CustomerPerformanceRecordOrder;
	setSortOrder: (value: CustomerPerformanceRecordOrder) => void;
	merchantId: string;
}

const CustomerPerformanceReportTable = ({ rows, sortOrder, setSortOrder, merchantId }: CustomerPerformanceReportTableProps): JSX.Element => {
	return (
		<StyledTable>
			<thead>
				<tr>
					<StyledTableHeader>Customer</StyledTableHeader>
					<StyledTableHeader>Email</StyledTableHeader>
					<StyledTableHeader selected={sortOrder === CustomerPerformanceRecordOrder.Referrals} onSelected={() => setSortOrder(CustomerPerformanceRecordOrder.Referrals)}>
						Referrals
					</StyledTableHeader>
					<StyledTableHeader selected={sortOrder === CustomerPerformanceRecordOrder.Shares} onSelected={() => setSortOrder(CustomerPerformanceRecordOrder.Shares)}>
						Shares
					</StyledTableHeader>
					<StyledTableHeader selected={sortOrder === CustomerPerformanceRecordOrder.Clicks} onSelected={() => setSortOrder(CustomerPerformanceRecordOrder.Clicks)}>
						Clicks
					</StyledTableHeader>
					<StyledTableHeader selected={sortOrder === CustomerPerformanceRecordOrder.Redemptions} onSelected={() => setSortOrder(CustomerPerformanceRecordOrder.Redemptions)}>
						Redemptions
					</StyledTableHeader>
				</tr>
			</thead>
			<tbody>
				{
					rows.map(x => (
						<tr key={x.customerId}>
							<td><Link key={x.customerId} to={`../../merchants/${merchantId}/customers/${x.customerId}`}>{x.customerName}</Link></td>
							<td>{x.email}</td>
							<td>{x.totalOrders}</td>
							<td>{x.totalShares}</td>
							<td>{x.totalClicks}</td>
							<td>{x.totalRedemptions}</td>
						</tr>
					))
				}
			</tbody>
		</StyledTable>
	);
};
