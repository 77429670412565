import { MutationFunction } from "react-query";
import { Response, useHTTP, useHTTPMutation } from "shared/lib/http";
import { useUserAuthState } from "src/features/auth/UserAuthState";
import { API_BASE_URL } from "src/lib/config";

export const useSimulateBillingCycle = () => {
	const { state } = useUserAuthState();
	const http = useHTTP(state.accessToken);

	const mutFunc: MutationFunction<Response<undefined>, string> = merchantId => {
		return http<undefined, undefined>({
			method: "POST",
			url: `${API_BASE_URL}/admin/merchant/${merchantId}/simulate-billing-cycle`
		});
	};

	return useHTTPMutation(mutFunc);
};
