import React from "react";
import styles from "./BoxContainer.module.scss";

export interface BoxContainerProps {
	children: React.ReactNode;
}

export const BoxContainer = ({ children }: BoxContainerProps): JSX.Element => {
	return (
		<div className={styles.BoxContainer}>
			{children}
		</div>
	);
};