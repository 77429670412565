import React from "react";
import { TitleBar } from "src/lib/components/TitleBar";
import { useTitle } from "src/lib/useTitle";

export const PageDashboard = (): JSX.Element => {
	useTitle("Dashboard");

	return (
		<TitleBar title="Dashboard" />
	);
};
