import React, { useCallback, useMemo, useState } from "react";
import styles from "./LinkBox.module.scss";
import { Button, ButtonStyle } from "./Button";
import link from "../assets/link.svg";
import { Toast, useToastController } from "./Toast";
import share from "../assets/share.svg";
import { ShareMechanism } from "../lib/domain";
import { useCobrandingSettings } from "shared/lib/theme";

export interface LinkBoxProps {
	referralLink: string;
	onEngagement?: (shareMechanism: ShareMechanism, nativeShareOffered: boolean, linkImmediatelyDisplayed: boolean) => void;
	merchantName: string;
	channelContext: ChannelContext;
	discount: string;
	enableLinkHiding?: boolean;
}

export enum ChannelContext {
	PostPurchase,
	OpenProgram
}

enum LinkStatus {
	Hidden,
	ManuallyDisplayed,
	AutomaticallyDisplayed
}

export const LinkBox = (props: LinkBoxProps): JSX.Element => {
	const {
		referralLink,
		onEngagement,
		merchantName,
		channelContext,
		discount,
		enableLinkHiding = false
	} = props;

	const copiedToastController = useToastController(1500);
	const [linkStatus, setLinkStatus] = useState(enableLinkHiding ? getRandomInitialLinkStatus() : LinkStatus.AutomaticallyDisplayed);

	const {
		enableCobranding,
		brandColor
	} = useCobrandingSettings();

	const onShareClicked = useMemo(() => {
		let text = "";

		if (channelContext === ChannelContext.OpenProgram) {
			text = `Hey! I have a link for ${discount} at ${merchantName} and thought you’d love it. Shop here: ${referralLink}`;
		} else if (channelContext === ChannelContext.PostPurchase) {
			text = `Hey! Just bought from ${merchantName} and thought you’d love it. Use this link to get ${discount}: ${referralLink}`;
		}

		const shareData = {
			text: text,
			title: ""
		};

		if (!navigator.canShare || !navigator.canShare(shareData)) {
			return undefined;
		}

		return () => {
			if (onEngagement) {
				onEngagement(ShareMechanism.Native, true, linkStatus === LinkStatus.AutomaticallyDisplayed);
			}

			navigator.share(shareData);
		};
	}, [referralLink, onEngagement]);

	const onCopyClickedEnriched = useCallback(() => {
		copiedToastController.show();
		navigator.clipboard.writeText(referralLink);

		if (onEngagement) {
			onEngagement(ShareMechanism.CopyLink, !!onShareClicked, linkStatus === LinkStatus.AutomaticallyDisplayed);
		}
	}, [referralLink, onEngagement]);

	const linkBoxStyle: React.CSSProperties = { borderColor: enableCobranding ? brandColor : "#5118EE" };

	if (linkStatus === LinkStatus.Hidden) {
		return (
			<div className={styles.buttonHolder}>
				<Button onClick={() => setLinkStatus(LinkStatus.ManuallyDisplayed)} text="Get my link" />
			</div>
		);
	}

	return (
		<>
			<div className={styles.linkBox} onClick={onCopyClickedEnriched} style={linkBoxStyle}>
				<div className={styles.link}>
					<div className={styles.linkLimit}>{referralLink}</div>
					<div className={styles.fader}></div>
				</div>

				<Button
					onClick={() => undefined} // onClick is on the outer div
					style={ButtonStyle.Filled}
					image={link}
					text={"Copy"} />
			</div>

			{
				onShareClicked &&
				<div className={styles.buttonHolder}>
					<Button text="Share Now" image={share} onClick={onShareClicked} />
				</div>
			}

			<Toast controller={copiedToastController}>
				Link Copied! 🎉
			</Toast>
		</>
	);
};

const getRandomInitialLinkStatus = (): LinkStatus => {
	return [
		LinkStatus.Hidden,
		LinkStatus.AutomaticallyDisplayed
	][Math.floor(Math.random() * 2)];
};
