import React from "react";
import styles from "./StyledTableHeader.module.scss";

export interface StyledTableHeaderProps {
	children: React.ReactNode;
	selected?: boolean;
	onSelected?: () => void;
}

export const StyledTableHeader = ({ children, selected, onSelected }: StyledTableHeaderProps): JSX.Element => {
	return (
		<th className={onSelected && styles.styledTableHeader} onClick={onSelected}>
			{children} {selected && <span>👆</span>}
		</th>
	);
};
