import { InfiniteQueryPageContext, PaginatedResponseModel, useHTTP, useHTTPQueryWithCursor } from "shared/lib/http";
import { useUserAuthState } from "src/features/auth/UserAuthState";
import { API_BASE_URL } from "src/lib/config";
import { GetMerchantsResponseModel } from "./models/GetMerchantsResponseModel";

export const useMerchants = () => {
	const { state } = useUserAuthState();
	const http = useHTTP(state.accessToken);

	return useHTTPQueryWithCursor(["merchants"], ({ pageParam = 0 }: InfiniteQueryPageContext) => {
		return http<PaginatedResponseModel<GetMerchantsResponseModel>>({
			method: "GET",
			url: `${API_BASE_URL}/admin/merchant?cursor=${pageParam}`
		});
	});
};
