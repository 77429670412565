import { Base64 } from "js-base64";
import { generateRandomStateToken } from "./generateRandomStateToken";

export interface PKCEGenerationResult {
	verifier: string;
	challenge: string;
}

export const generatePKCECodeVerifierAndChallenge = async (): Promise<PKCEGenerationResult> => {
	const textEncoder = new TextEncoder();
	const verifier = generateRandomStateToken(48);
	const hash = await crypto.subtle.digest("SHA-256", textEncoder.encode(verifier));
	const challenge = Base64.fromUint8Array(new Uint8Array(hash), true);

	return {
		verifier,
		challenge
	};
};
