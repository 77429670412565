import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffectOnFirstMount } from "shared/hooks";

export const PageIndex = (): JSX.Element => {
	const navigate = useNavigate();
	useEffectOnFirstMount(() => navigate("/dashboard"));

	return (
		<></>
	);
};
