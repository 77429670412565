import React from "react";
import { UgcSubmissionMediaItem } from "src/features/ugc/api/models/UgcSubmissionResponseModel";
import styles from "./MediaList.module.scss";

export interface MediaListProps {
	medias: UgcSubmissionMediaItem[];
}
export const MediaList = ({ medias }: MediaListProps): JSX.Element => {
	return (
		<div className={styles.imageContainer}>
			{medias.map((x, i) => {
				return (
					<a href={x.url} target="_blank" key={i} rel="noreferrer">
						<img
							key={i}
							className={styles.img}
							src={`${x.url}_thumbnail`}
							alt={x.url}
						/>
					</a>
				);
			})}
		</div>
	);
};