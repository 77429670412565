import React, { useEffect, useState } from "react";
import tickIcon from "src/assets/tick.svg";
import crossIcon from "src/assets/cross.svg";
import { Container } from "src/lib/components/Container";
import { Limit } from "src/lib/components/Limit";
import { StyledTable } from "src/lib/components/StyledTable";
import { StyledTableHeader } from "src/lib/components/StyledTableHeader";
import { TitleBar } from "src/lib/components/TitleBar";
import { ListManualInvoicesResponseModel, useManualInvoices } from "../api/useManualInvoices";
import { Center, ErrorDisplay, InfiniteScroller, Loader, ModalBase, MoneyDisplay, SmartBox } from "shared/components";
import { ContainerSegment } from "src/lib/components/ContainerSegment";
import { InfiniteData } from "react-query";
import { PaginatedResponseModel } from "shared/lib/http";
import dayjs from "dayjs";
import { Tag, TagColor } from "src/lib/components/Tag";
import { ContainerListButton } from "src/lib/components/ContainerListButton";
import { useSettleManualInvoice } from "../api/useSettleManualInvoice";
import { useTitle } from "src/lib/useTitle";

export const PageManualInvoices = (): JSX.Element => {
	useTitle("Manual Invoices");

	const manualInvoices = useManualInvoices();

	return (
		<Limit>
			<TitleBar title="Manual Invoices" />

			<Container>
				<SmartBox
					switchOn={manualInvoices}
					loading={() => <ContainerSegment><Center><Loader /></Center></ContainerSegment>}
					error={err => <ContainerSegment><ErrorDisplay problemResponse={err} /></ContainerSegment>}
					success={data => <ManualInvoiceTable manualInvoices={data} />} />
			</Container>

			<InfiniteScroller forQuery={manualInvoices} />
		</Limit>
	);
};

interface ManualInvoiceTableProps {
	manualInvoices: InfiniteData<PaginatedResponseModel<ListManualInvoicesResponseModel>>;
}

const ManualInvoiceTable = ({ manualInvoices }: ManualInvoiceTableProps): JSX.Element => {
	const [modalInvoiceId, setModalInvoiceId] = useState<string | null>(null);

	return (
		<>
			<StyledTable>
				<thead>
					<tr>
						<StyledTableHeader>Date</StyledTableHeader>
						<StyledTableHeader>Merchant</StyledTableHeader>
						<StyledTableHeader>Status</StyledTableHeader>
						<StyledTableHeader>Amount</StyledTableHeader>
						<StyledTableHeader> </StyledTableHeader>
					</tr>
				</thead>
				<tbody>
					{
						manualInvoices.pages.flatMap(x => x.results).map(x => (
							<tr key={x.invoiceId}>
								<td>{dayjs(x.created).format("DD MMM YYYY")}</td>
								<td>{x.merchantName}</td>
								<td>
									<Tag
										text={x.status}
										color={x.status === "Paid" ? TagColor.Disabled : TagColor.Bright} />
								</td>
								<td><MoneyDisplay amount={x.amount} /></td>
								<td>
									{
										x.status === "Billed" &&
										<a onClick={() => setModalInvoiceId(x.invoiceId)}>Mark Paid</a>
									}
								</td>
							</tr>
						))
					}
				</tbody>
			</StyledTable>

			<ConfirmModal invoiceId={modalInvoiceId} onCancel={() => setModalInvoiceId(null)} />
		</>
	);
};

interface ConfirmModalProps {
	invoiceId: string | null;
	onCancel: () => void;
}

const ConfirmModal = ({ invoiceId, onCancel }: ConfirmModalProps): JSX.Element => {
	const settleManualInvoice = useSettleManualInvoice();

	useEffect(() => {
		if (!settleManualInvoice.isSuccess) {
			return;
		}

		onCancel();
	}, [settleManualInvoice.isSuccess]);

	const onConfirm = () => {
		if (!invoiceId) {
			return;
		}

		settleManualInvoice.mutate(invoiceId);
	};

	return (
		<ModalBase visible={!!invoiceId}>
			<Container>
				<ContainerSegment>
					<b>Mark Paid</b><br />
					Are you sure you want to mark this invoice as paid?
				</ContainerSegment>

				{
					settleManualInvoice.isLoading &&
					<ContainerSegment>
						<Center>
							<Loader />
						</Center>
					</ContainerSegment>
				}

				{
					settleManualInvoice.error &&
					<ContainerSegment>
						<ErrorDisplay problemResponse={settleManualInvoice.error} />
					</ContainerSegment>
				}

				{
					!settleManualInvoice.isLoading &&
					<>
						<ContainerListButton text="Confirm" icon={tickIcon} onClick={onConfirm} />
						<ContainerListButton text="Cancel" icon={crossIcon} onClick={onCancel} />
					</>
				}
			</Container>
		</ModalBase>
	);
};
