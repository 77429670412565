import { MutationFunction, useQueryClient } from "react-query";
import { Response, useHTTP, useHTTPMutation } from "shared/lib/http";
import { useUserAuthState } from "src/features/auth/UserAuthState";
import { API_BASE_URL } from "src/lib/config";

export const useSettleManualInvoice = () => {
	const queryClient = useQueryClient();
	const { state } = useUserAuthState();
	const http = useHTTP(state.accessToken);

	const mutFunc: MutationFunction<Response<undefined>, string> = invoiceId => {
		return http({
			method: "POST",
			url: `${API_BASE_URL}/admin/manual-invoice/${invoiceId}/settle`
		});
	};

	return useHTTPMutation(mutFunc, {
		onSuccess: () => {
			queryClient.invalidateQueries(["manualInvoices"]);
		}
	});
};
