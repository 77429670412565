import { AD_ADMIN_APP_CLIENT_ID, ARM_TENANT_ID } from "src/lib/config";
import { useUserAuthState } from "../UserAuthState";

export const useADLogout = () => {
	const { dispatchLogout } = useUserAuthState();

	return () => {
		dispatchLogout();
		window.location.href = `https://login.microsoftonline.com/${ARM_TENANT_ID}/oauth2/v2.0/logout?client_id=${AD_ADMIN_APP_CLIENT_ID}`;
	};
};
