import { useHTTP, useHTTPQuery } from "shared/lib/http";
import { useUserAuthState } from "src/features/auth/UserAuthState";
import { API_BASE_URL } from "src/lib/config";
import { GetMerchantResponseModel } from "./models/GetMerchantResponseModel";

export const useMerchant = (merchantId: string) => {
	const { state } = useUserAuthState();
	const http = useHTTP(state.accessToken);

	return useHTTPQuery(["merchant", merchantId], () => {
		return http<GetMerchantResponseModel>({
			method: "GET",
			url: `${API_BASE_URL}/admin/merchant/${merchantId}`
		});
	});
};
